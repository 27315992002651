import React from "react"
import newIcon from '../images/new.svg';

const Plato = ({ platoFeatured, platoNew, platoNombre, platoPrecio, platoDescripcion }) => {
  const featured = ( platoFeatured === true ) ? ' featured bg-red-700 text-white rounded-lg -mr-6 p-4 pr-6' : '';
  const platoNewClass = ( platoNew === true ) ? ' pl-8' : '';
  return (
    <div className={`relative${ featured }`}>
      { platoNew ? ( <img src={newIcon} alt='' className="absolute t-0 l-0 h-8 w-8" /> ) : null }
      <h4 className={ `item-name text-lg font-bold border-b-2 border-dotted mb-3 pr-10 ${ platoNewClass }` }>
        {platoNombre}
      </h4>
      { platoPrecio ? ( 
        <span className="price bg-orange-400 font-bold text-white text-center leading-10 border-2 border-white rounded-full w-16 h-16 -mt-12 -mr-3 p-2 float-right">
          {platoPrecio}<sub>€</sub>
        </span>
      ) : null }
      <p>{platoDescripcion}</p>
    </div>
  )
}
export default Plato