import React, { Component } from "react"
import Plato from "./platos"

const ListaTabla = ({ tablaPrecios, tablaPlatos }) => {
  return (
    <table className="w-full table-auto mb-6">
      <thead>
        <tr>
          <th></th>
          {tablaPrecios.map((data, index) => {
            return <th key={`heading_${index}`} className="text-right p-3">{data.nombre}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {tablaPlatos.map((data, index) => {
          return (
            <tr key={`row_${index}`} className="border-b-2 border-dotted">
              <td className="py-3 pr-3">{data.nombre}</td>
              <td className="text-right py-3 pr-3">{data.precio_1}</td>
              <td className="text-right py-3 pr-3">{data.precio_2}</td>
              <td className="text-right py-3">{data.precio_3}</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const ListaPlatos = ({ categoriaPlatos }) => {
  return (
      <div className={`section-grid grid md:grid-cols-1 xl:grid-cols-2 gap-12`}>
        {categoriaPlatos.map((data, index) => {
          return <Plato key={`plato_${index}`} platoFeatured={data.featured} platoNew={data.new}  platoNombre={data.nombre} platoPrecio={data.precio} platoDescripcion={data.descripcion} />
        })}
      </div>
  )
}

class Categoria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: true,
    };
  }

  toggleSection = (name) => {
    if(this.state.isShow) {
      window.gtag('event', 'click', {
        event_category: 'Carta',
        event_label: name,
      })
    }
    this.setState(state => ({ isShow: !state.isShow }));
  }

  render() {
    return (
      <section className={`${this.state.isShow ? 'section-toggled' : 'section-hidden pb-6'} container mx-auto relative ${this.props.categoriaTipo === 'tabla' ? 'py-6' : 'p-6 pr-8'} md:px-12 lg:px-24 pb-0 md:pb-12 mb-12 bg-gray-100 border-4 border-double border-orange-400`}>
        <h3 className="text-xl font-bold text-red-700 uppercase tracking-widest text-center mb-6 cursor-pointer" onClick={()=>this.toggleSection(this.props.categoriaNombre)}>{this.props.categoriaNombre}</h3>
        <button className="section-toggle outline-none border-0 focus:border-2 " onClick={()=>this.toggleSection(this.props.categoriaNombre)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#c53030"><path d="M24 12c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12zm-18.005-1.568l1.415-1.414 4.59 4.574 4.579-4.574 1.416 1.414-5.995 5.988-6.005-5.988z"/></svg>
        </button>
        { this.props.categoriaTipo === 'tabla' ?
          <ListaTabla tablaPrecios={this.props.categoriaPrecios} tablaPlatos={this.props.categoriaPlatos} /> : 
          <ListaPlatos categoriaPlatos={this.props.categoriaPlatos} />
        }
      </section>
    )
  }
}
export default Categoria